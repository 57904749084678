import {Route, Routes} from "react-router-dom";
import CutJob from "../../pages/CutJobPage/CutJob.tsx";
import OverviewPage from "../../pages/OverviewPage/OverviewPage.tsx";

function Router(){

    return(
            <Routes>
                <Route path={"/job/:jobId/stepByStep"} element={<CutJob animation={true}/>}/>
                <Route path={"/job/:jobId"} element={<CutJob/>}/>
                <Route path={"/"} element={<OverviewPage/>}/>
            </Routes>
    )

}
export default Router;