import {
    alpha,
    AppBar,
    Avatar,
    Box,
    Container,
    Divider,
    Drawer,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Menu,
    MenuItem,
    styled,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import React, {ReactNode} from "react";
import SearchIcon from "@mui/icons-material/Search"
import MenuIcon from "@mui/icons-material/Menu"
import KalibratorLogo from "../../../assets/Calibrator_Blue.svg"
import {useAuth} from "oidc-react";
import {useNavigate} from "react-router-dom";


export interface DesktopNavigationProps {
    children: ReactNode
}


const Search = styled("div")(({theme}) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.grey[500], 0.15),
    "&:hover": {
        backgroundColor: alpha(theme.palette.grey[500], 0.25)
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto"
    }
}));

const SearchIconWrapper = styled("div")(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create("width"),
        [theme.breakpoints.up("sm")]: {
            width: "12ch",
            "&:focus": {
                width: "20ch"
            }
        }
    }
}));

function DesktopNavigation(props: DesktopNavigationProps) {

    const navigate = useNavigate();

    const pages2 = [{title: "my projects", onClick: () => navigate("/")}] as {
        title: string,
        onClick: () => void,
        type?: "spacer",
        element?: any
    }[]

    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

    const auth = useAuth();

    const settings = [
        {title: "Logout", onClick: () => auth.signOutRedirect().then(() => window.location.reload())}
    ];

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    return (
        <>
            <AppBar position="static">
                <Container maxWidth={false}>
                    <Toolbar disableGutters sx={{display: "flex", alignItems: "center"}}>
                        <Box component={"img"} src={KalibratorLogo} height={"1.5rem"}
                             onClick={() => window.location.pathname = "/"} alt={"Kalibrator Logo"} sx={{
                            mr: 2
                        }}/>

                        <Box sx={{flexGrow: 1, display: {xs: "flex", md: "none"}}}>
                            <Box sx={{
                                display: {xs: "flex", md: "none"}
                            }}/>

                        </Box>

                        <Box sx={{flexGrow: 1, gap: 1, display: {xs: "none", md: "flex"}, alignItems: "center"}}>

                            {pages2.map((page) => {
                                if (page.type === "spacer") {
                                    return <Box/>;
                                }
                                return (
                                    <Typography
                                        noWrap
                                        key={page.title}
                                        onClick={() => {
                                            handleCloseNavMenu()
                                            page.onClick()
                                        }}
                                    >
                                        {page?.title}
                                    </Typography>
                                );
                            })}

                        </Box>

                        <Box sx={{flexGrow: 0, display: "flex", alignItems: "center"}}>
                            <Box sx={{pr: 2, display: {xs: "none", md: "block"}}}>
                                <Search>
                                    <SearchIconWrapper>
                                        <SearchIcon/>
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Search…"
                                        inputProps={{"aria-label": "search"}}
                                    />
                                </Search>
                            </Box>
                            <Box>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                    sx={{
                                        display: {xs: "flex", md: "none"},
                                        mr: 1

                                    }}
                                >
                                    <MenuIcon/>
                                </IconButton>
                            </Box>
                            <Box>
                                <Tooltip title="Profile">
                                    <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                        <Avatar alt={"Profile Picture of "}
                                                src={""}
                                        >
                                            {auth?.userData?.profile?.preferred_username[0]?.toUpperCase()}
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                anchor={"right"}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
            >
                <Box component={"img"} src={KalibratorLogo} height={"1.5rem"}
                     onClick={() => window.location.pathname = "/"} alt={"Kalibrator Logo"} sx={{
                    mr: 2
                }}/>
                <Divider/>
                <Box sx={{width: "60vw"}}>
                    <List>
                        {pages2.map((item, index) => {
                            if (item.type === "spacer") {
                                return <Box sx={{height: ".75rem"}}></Box>;
                            }
                            if (item.element) {
                                return item.element;
                            }
                            return (
                                <ListItem key={item.title} disablePadding onClick={() => item.onClick()}>
                                    <ListItemButton>
                                        <ListItemText primary={item.title}/>
                                    </ListItemButton>
                                </ListItem>
                            );
                        })}
                    </List>
                </Box>
            </Drawer>
            <Menu
                sx={{mt: "45px"}}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                keepMounted
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {settings.map((setting) => (
                    <MenuItem key={setting.title} onClick={setting.onClick}>
                        <Typography textAlign="center">{setting.title}</Typography>
                    </MenuItem>
                ))}
            </Menu>
            {props.children}
        </>
    )
}

export default DesktopNavigation;