import React, {useEffect, useState} from "react";
import {closeSnackbar, enqueueSnackbar} from "notistack";
import {ReadyState} from "react-use-websocket";
import {Box, CircularProgress, Stack} from "@mui/material";

export interface useWebsocketNotifierProps{
    readyState: ReadyState
}

export function useWebsocketNotifier(props: useWebsocketNotifierProps){
    const [snackBarState, setSnackBarState] = useState<{ id: string, lastState: ReadyState }>(null)

    useEffect(() => {
        console.log("Update", props.readyState)
        let snackbarId;

        if (snackBarState && snackBarState.lastState === props.readyState) {
            console.log("Same state triggered")
            return;
        }

        if (snackBarState && snackBarState.lastState !== props.readyState) {
            console.log("Closing previous snackbar")
            closeSnackbar(snackBarState.id)
        }

        switch (props.readyState) {
            /*case ReadyState.CONNECTING:
                snackbarId = enqueueSnackbar(<Stack spacing={1} direction={"row"}><CircularProgress size={20} color={"inherit"}/><Box>Connecting</Box></Stack>, {
                    variant: 'default',
                })
                break*/
            case ReadyState.OPEN:
                if (snackBarState.lastState !== ReadyState.CLOSED) {
                    return;
                }
                snackbarId = enqueueSnackbar('Connected', {
                    variant: 'success',
                })
                break
            case ReadyState.UNINSTANTIATED:
            case ReadyState.CLOSED:
                console.log("enqueue snackbar disconnect")
                snackbarId = enqueueSnackbar('Disconnected', {
                    variant: 'error',
                    persist: true
                })
                break
            case ReadyState.CLOSING:
                snackbarId = enqueueSnackbar(<Stack spacing={1} direction={"row"}><CircularProgress size={20}
                                                                                                    color={"inherit"}/><Box>Closing</Box></Stack>, {
                    variant: 'default',
                    autoHideDuration: 3000
                })
                break

        }
        setSnackBarState({id: snackbarId, lastState: props.readyState})

    }, [props.readyState]);
}