import {useQuery} from "@tanstack/react-query";
import {useCallback, useEffect, useMemo} from "react";
import JobRest from "../../rest/JobRest.ts";
import Statement from "../../components/Statements/Statement.tsx";
import {SentimentVeryDissatisfied} from "@mui/icons-material";
import {Container, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {CardGrid, StandardCard} from "@laser-project/kyui";
import {IJob} from "../../types/models/Job.ts";
import {useDropzone} from "react-dropzone";
import PlusPlaceholder from "../../assets/plusPlaceholder.svg"
import ImageRest from "../../rest/ImageRest.ts";
import useWebSocket from "react-use-websocket";
import {useWebsocketNotifier} from "../../hooks/useWebsocketNotifier/useWebsocketNotifier.tsx";
import JobWebsocket from "../../websocket/JobWebsocket.ts";
import WebsocketAPISerde from "../../types/WebsocketAPISerde.ts";
import {KNOWN_WEBSOCKET_API_MESSAGES} from "../../types/WebsocketAPITypes.ts";

function OverviewPage() {

    const jobRest = useMemo(() => new JobRest(), []);
    const imageRest = useMemo(() => new ImageRest(), []);
    const jobWebsocket = useMemo(() => new JobWebsocket(), []);
    const navigate = useNavigate();


    const {isPending, error, data, refetch, } = useQuery({
        queryKey: ['jobs'],
        queryFn: () =>
            jobRest.get().then(res => res.data as { data: IJob[] })
    })

    const {lastMessage, readyState} = useWebSocket(
        jobWebsocket.getAllUpdates(),
        {
            shouldReconnect: () => true,
            heartbeat: true
        });

    useWebsocketNotifier({readyState: readyState})

    useEffect(() => {
        if (!lastMessage) {
            console.log("rejected last message because it is empty", lastMessage)
            return
        }

        try {
            const message = WebsocketAPISerde.decode(lastMessage.data);
            switch (message.type) {
                case KNOWN_WEBSOCKET_API_MESSAGES.CREATE_JOB: {
                    refetch()
                    break;
                }
                case KNOWN_WEBSOCKET_API_MESSAGES.PONG:
                    break;
            }
        } catch
            (e) {
            console.error("Failed to parse Message sent by cutterManager", e, lastMessage)
        }

    }, [lastMessage, refetch]);


    const onDrop = useCallback((acceptedFiles: File[]) => {
        acceptedFiles.forEach((file: File) => {
            console.log("Uploaded file", file)
            const reader = new FileReader()

            reader.onabort = () => console.error('file reading was aborted')
            reader.onerror = () => console.error('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr: AllowSharedBufferSource = reader.result as AllowSharedBufferSource
                if (!binaryStr) {
                    console.log("failed to read file")
                    return
                }

                if (file.type === "image/svg+xml"){
                    const data = new FormData();
                    data.append("file", file)
                    jobRest.createJobByFile(data)
                    return
                }

                const cutplanContent: string = new TextDecoder("utf-8").decode(binaryStr);
                console.log(cutplanContent)
                jobRest.createJob({
                    title: file.name,
                    cutplan: cutplanContent,
                    sheetPrototype: {
                        "outline": {
                            "points":
                                [{"x": 0, "y": 0}, {"x": 594, "y": 0}, {"x": 594, "y": 420}, {"x": 0, "y": 420}]
                        }
                    }
                })
                    .then(() => refetch())

            }
            reader.readAsArrayBuffer(file)
        })
    }, [jobRest, refetch])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop, accept: {
            'application/xml': ['.cut'],
            'image/svg+xml': ['.svg']
        }
    });

    function generateCards() {
        const addCard = (
            <div {...getRootProps()}>
                <input {...getInputProps()}/>
                <StandardCard
                    key={"add"}
                    image={PlusPlaceholder}
                    imageAlt={"Image of an Plus"}
                    title={isDragActive ? "" +
                        "Drop item here" : "Drop item here, or click to select to get started"}
                    chips={[]}
                    type={"model"}
                />
            </div>
        )

        if (!data) {
            return [addCard]
        }
        const modelCards = data?.data?.map((job: IJob, idx: number) => (
            <StandardCard
                key={idx}
                image={imageRest.getUrlById(job.imageId)}
                imageAlt={"Rendered Model of " + job.title}
                title={job.title ?? "No title given"}
                chips={[]}
                onClick={() => navigate("/job/" + job._id)}
                type={"model"}
                menuActions={[
                    {
                        title: "Delete",
                        onClick: () => jobRest.delete(job._id).then(() => refetch())
                    }
                ]}
            />
        ))

        return [addCard, ...modelCards]
    }


    if (error) {
        return <Statement message={error.message} icon={<SentimentVeryDissatisfied/>}/>
    }

    return (
        <Container sx={{pt: 2}}>
            <Typography variant={"h3"}>Jobs</Typography>
            <CardGrid
                isLoading={isPending}
                cards={generateCards()}

            />
        </Container>
    )

}

export default OverviewPage