import {IJob, JOB_STATUS} from "../../../types/models/Job.ts";
import {Box, Skeleton, Stack, Typography} from "@mui/material";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner.tsx";
import {CardSlider} from "../CardSlider/CardSlider.tsx";
import {Expandable} from "@laser-project/kyui";
import {TransformAnimation} from "../../../components/Animations/TransformAnimation/TransformAnimation.tsx";
import React from "react";
import {IGaugeDedupe} from "../CutJob.tsx";
import {FeatureSlider} from "../FeatureSlider/FeatureSlider.tsx";
import {IFeature} from "../../../types/models/Feature.ts";
import {IAction, IActionSpec} from "../../../types/models/Action.ts";
import {GaugesSlider} from "../GaugesSlider/GaugesSlider.tsx";

export interface StepByStepInterface {
    filteredFeatures: (IFeature[] | null),
    job: IJob,
    renderPreviewStepTarget: number,
    flattenedActionList: (IAction[] | IActionSpec[] | undefined)
    actionId: string,
    isSubmitting: boolean,
    handleUserInputSubmit: (answeredInput: object) => Promise<void>
}

export function StepByStep(props: Readonly<StepByStepInterface>) {

    if (JOB_STATUS.CREATED === props.job?.status) {
        return (
            <Stack spacing={3}>
                <LoadingSpinner message={"Model is being analyzed"}/>
                <Typography variant={"h1"} gutterBottom>{props.job?.title ?? <Skeleton/>}</Typography>
                <FeatureSlider filteredFeatures={props.filteredFeatures}/>
            </Stack>
        )
    }
    if (props.renderPreviewStepTarget === 7) {
        return (
            <Stack spacing={3}>
                <Typography variant={"h1"} gutterBottom>{props.job?.title ?? <Skeleton/>}</Typography>
                <CardSlider
                    handleUserInputSubmit={props.handleUserInputSubmit}
                    elements={props.flattenedActionList}
                    activeElementId={props.actionId}
                    currentSubmitting={props.isSubmitting}
                />
                <Expandable title={"Gauges"}>
                    <GaugesSlider filteredFeatures={props.filteredFeatures}/>
                </Expandable>
                <Expandable title={"Features"}>
                    <FeatureSlider filteredFeatures={props.filteredFeatures}/>
                </Expandable>
            </Stack>
        )
    }

    return (
        <Stack spacing={3}>
            <Typography variant={"h1"} gutterBottom>{props.job?.title ?? <Skeleton/>}</Typography>
            <Box sx={{paddingTop: 1, paddingBottom: 1}}>
                <TransformAnimation
                    cards={props.job?.features?.map(feature => {
                        return {
                            primary: feature,
                            secondary: feature.dependencies as IGaugeDedupe[]
                        }
                    }) ?? []}
                    animationTarget={props.renderPreviewStepTarget}
                    onComplete={() => {
                        if (props.renderPreviewStepTarget === 5) {
                        }
                        console.log("calling set done")
                    }}
                />
            </Box>
        </Stack>
    )

}